.drop {
  display: flex;
  justify-content: flex-start;
  background-color: #223343;
  height: 20px;
  width: 400px;
  /* padding: 1px; */
}

.drop > input {
  width: 30px;
}

.dropbtn {
  border-radius: 0.2rem;
  background-color: white;
  color: black;
  width: 110px;
  height: 30px;
}

.dropvalue {
  padding: 3px;
  position: absolute;
  top: 100%;
  height: 300px;
  border-radius: 0.5rem;

  overflow-y: scroll;
  z-index: 10;
}
.ckinput {
  accent-color: #223343;
}
.outer-drop {
  width: 90%;
  position: relative;
}
