.popu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    display: flex; 
    justify-content: center;
    overflow-y: scroll;
    
}
.aslink{
    color: rgb(109, 109, 243);
}
.aslink:hover{
    text-decoration: underline;
    cursor: pointer;
}