.inner-graph{
    height: 90%;
    aspect-ratio: 1;
    background-color: #eaf0f6;
}

.centroid{
    width: 0;
    height: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.node{
    position: absolute;
    transform: translate(-50%,-50%);
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: #b8c2cc;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.node:hover{
    cursor: pointer;
}
.circle{
    position: absolute;
    aspect-ratio: 1;
    background-color: #eaf0f6;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid black;
}
.node-text{
    position: absolute;
    font-size: small;
    color: #8796a4;
    transform: translate((-50%,-50%));

}