html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  gap: 0;
  /* background-color: red; */
}

#root {
  height: 100%;
}

.main {
  /* top: -20px; */
  margin-top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: #eaf0f6;
  display: flex;
  flex-direction: row;
}

.graph-container {
  height: 90%;
  width: 60%;
  margin: 2%;
  margin-top: -1%;
  background-color: #eaf0f6;
  border-radius: 30px;


  display: flex;
  justify-content: center;
  align-items: center;


}

::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid black;
  /* creates padding around scroll thumb */
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.recharts-legend-item {
  margin-top: 23px;
  margin-left: 15%;
}